<template>
  <div class="page-content">
    <div class="card row">
      <div class="card-body col-md-12">
        <div class="row col-md-8 d-flex justify-content-between align-items-center">
          <div class="col-9 col-sm-9">
            <form class="search-form" @submit.prevent="doSearch()">
              <div class="input-group border rounded-sm">
                <div class="input-group-prepend">
                  <div class="input-group-text border-0 rounded-sm">
                    <FeatherIcon type="search" class="icon-md cursor-pointer" />
                  </div>
                </div>
                <input v-model="search.search" type="text" class="form-control  border-0 rounded-sm" id="searchForm" placeholder="Search here...">
              </div>
            </form>
          </div>
          <div class="col-3 col-sm-3">
            <button v-if="checkPermission.create" class="btn btn-primary btn-block" @click="addUser">Add User</button>
          </div>
        </div>
        <p class="text-muted mt-3">Users</p>
        <div class="mt-2" v-loading="loading.table">
          <table class="table table-hover">
            <thead>
              <tr>
                <th>Name</th>
                <th>Email</th>
                <th>Phone Number</th>
                <th>Added Date</th>
                <th>Last Login</th>
                <th colspan="4">#</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in usersList" :key="item.id">
                <td>{{ item.name }}</td>
                <td>{{ item.email }}</td>
                <td>{{ item.phone_number }}</td>
                <td>{{ item.created_at_str }}</td>
                <td>{{ item.last_login_at_str }}</td>
                <td>
                  <button v-if="checkPermission.update" class="btn btn-outline-primary btn-sm mr-2" @click="editUser(item)">
                    <font-awesome icon="user-edit"/>
                  </button>
                  <button v-if="checkPermission.delete" class="btn btn-outline-primary btn-sm" @click="deleteUser(item.id)">
                    <font-awesome icon="times"/>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <el-empty v-if="totalRow == 0" description="No data"></el-empty>
        <b-pagination v-if="totalRow > search.per_page" v-model="currentPage" :total-rows="totalRow" :per-page="search.per_page"/>
      </div>
    </div>

    <b-modal v-model="showModal" title="Add New User" hide-footer>
      <b-form @submit.prevent="createUser" @reset="closeModal">
        <b-form-group id="ig-user-name" label="Name:" label-for="i-user-name">
          <b-form-input :placeholder="$t('users.placeholder.name')" autocomplete="off" id="i-user-name" type="text" v-model="v$.userForm.name.$model" />
          <b-form-invalid-feedback :state="!v$.userForm.name.$invalid" v-if="v$.userForm.name.$errors[0]">
            {{ $t('validation.' + v$.userForm.name.$errors[0].$validator) }}
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group id="ig-phone-number" label="Phone Number:" label-for="i-phone-number">
          <b-form-input :placeholder="$t('users.placeholder.phone_number')" autocomplete="one-time-password" id="i-phone-number" type="text" v-model="v$.userForm.phone_number.$model" />
          <b-form-invalid-feedback :state="!v$.userForm.phone_number.$invalid" v-if="v$.userForm.phone_number.$errors[0]">
            <span v-if="!['maxLength', 'minLength'].includes(v$.userForm.phone_number.$errors[0].$validator)">{{ $t('validation.' + v$.userForm.phone_number.$errors[0].$validator) }}</span>
            <span v-else>{{ v$.userForm.phone_number.$errors[0].$message }}</span>
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group id="ig-user-email" label="Email:" label-for="i-user-email">
          <b-form-input :placeholder="$t('users.placeholder.email')" autocomplete="one-time-password" id="i-user-email" type="text" v-model="v$.userForm.email.$model" />
          <b-form-invalid-feedback :state="!v$.userForm.email.$invalid" v-if="v$.userForm.email.$errors[0]">
            {{ $t('validation.' + v$.userForm.email.$errors[0].$validator) }}
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group id="ig-password" label="Password:" label-for="i-passwordl">
          <b-form-input :placeholder="$t('users.placeholder.password')" autocomplete="one-time-code" id="i-password" type="password" v-model="v$.userForm.password.$model" />
          <b-form-invalid-feedback :state="!v$.userForm.password.$invalid" v-if="v$.userForm.password.$errors[0]">
            <span v-if="!['maxLength', 'minLength'].includes(v$.userForm.password.$errors[0].$validator)">{{ $t('validation.' + v$.userForm.password.$errors[0].$validator) }}</span>
            <span v-else>{{ v$.userForm.password.$errors[0].$message }}</span>
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group id="ig-pic" label="Set as PIC:" label-for="i-pic">
          <el-switch
            v-model="userForm.is_pic"
            active-color="#13ce66"
            inactive-color="#ff4949" />
        </b-form-group>
        <b-form-group id="ig-pic" label="Divisions:" label-for="i-pic">
          <el-select v-model="userForm.groups_id" :filter-method="handlerSearchDivision" filterable multiple placeholder="Select divisions" class="w-100">
            <el-option
              v-for="item in division_list"
              :key="item.id"
              :label="item.name"
              :value="item.id">
            </el-option>
          </el-select>
          <b-form-invalid-feedback :state="!v$.userForm.groups_id.$invalid" v-if="v$.userForm.groups_id.$errors[0]">
            <span v-if="!['maxLength', 'minLength'].includes(v$.userForm.groups_id.$errors[0].$validator)">{{ $t('validation.' + v$.userForm.groups_id.$errors[0].$validator) }}</span>
            <span v-else>{{ v$.userForm.groups_id.$errors[0].$message }}</span>
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group id="ig-pic" label="Set as Division Admin:" label-for="i-pic">
          <el-switch
            v-model="userForm.is_admin"
            active-color="#13ce66"
            inactive-color="#ff4949" />
        </b-form-group>
        <div class="d-flex flex-row-reverse">
          <b-button type="submit" variant="primary" class="ml-2">{{ $t('general.submit') }}</b-button>
          <b-button type="reset" variant="secondary">{{ $t('general.cancel') }}</b-button>
        </div>
      </b-form>
    </b-modal>
    <b-modal v-model="showModalEdit" title="Edit User" hide-footer>
      <b-form @submit.prevent="saveEdit" @reset="closeModal">
        <b-form-group id="ig-user-name" label="Name:" label-for="i-user-name">
          <b-form-input id="i-user-name" type="text" v-model="userForm.name" />
          <b-form-invalid-feedback :state="!v$.userForm.name.$invalid" v-if="v$.userForm.name.$errors[0]">
            {{ $t('validation.' + v$.userForm.name.$errors[0].$validator) }}
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group id="ig-phone-number" label="Phone Number:" label-for="i-phone-number">
          <b-form-input id="i-phone-number" type="tel" v-model="userForm.phone_number" />
          <b-form-invalid-feedback :state="!v$.userForm.phone_number.$invalid" v-if="v$.userForm.phone_number.$errors[0]">
            <span v-if="!['maxLength', 'minLength'].includes(v$.userForm.phone_number.$errors[0].$validator)">{{ $t('validation.' + v$.userForm.phone_number.$errors[0].$validator) }}</span>
            <span v-else>{{ v$.userForm.phone_number.$errors[0].$message }}</span>
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group id="ig-user-email" label="Email:" label-for="i-user-email">
          <b-form-input id="i-user-email" type="email" v-model="userForm.email" />
          <b-form-invalid-feedback :state="!v$.userForm.email.$invalid" v-if="v$.userForm.email.$errors[0]">
            {{ $t('validation.' + v$.userForm.email.$errors[0].$validator) }}
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group id="ig-password" label="Password:" label-for="i-passwordl">
          <b-form-input :placeholder="$t('users.placeholder.password')" autocomplete="one-time-code" id="i-password" type="password" v-model="v$.userForm.password.$model" />
          <b-form-invalid-feedback :state="!v$.userForm.password.$invalid" v-if="v$.userForm.password.$errors[0]">
            <span v-if="!['maxLength', 'minLength'].includes(v$.userForm.password.$errors[0].$validator)">{{ $t('validation.' + v$.userForm.password.$errors[0].$validator) }}</span>
            <span v-else>{{ v$.userForm.password.$errors[0].$message }}</span>
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group id="ig-pic" label="Set as PIC:" label-for="i-pic">
          <el-switch
            v-model="userForm.is_pic"
            active-color="#13ce66"
            inactive-color="#ff4949" />
        </b-form-group>

        <div class="d-flex flex-row-reverse">
          <b-button type="submit" variant="primary" class="ml-2">{{ $t('general.submit') }}</b-button>
          <b-button type="reset" variant="secondary">{{ $t('general.cancel') }}</b-button>
        </div>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import moment from 'moment';
import { isEqual } from 'lodash';
import useVuelidate from '@vuelidate/core';
import {
  required, email, numeric, minLength, maxLength, requiredIf,
} from '@vuelidate/validators';

import usersAPI from '../../../api/users';
import avatar from '../../../library/avatar';
import RSA from '../../../library/rsa-encrypt';
import popupErrorMessages from '../../../library/popup-error-messages';
import divisions from '../../../api/divisions';

export default {
  name: 'Users',
  metaInfo: {
    title: 'Users',
  },
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  validations() {
    return {
      userForm: {
        name: {
          required,
        },
        phone_number: {
          required,
          numeric,
          minLength: minLength(9),
          maxLength: maxLength(15),
        },
        email: {
          required,
          email,
        },
        password: {
          required: requiredIf(this.action === 'add'),
          minLength: minLength(8),
        },
        groups_id: {
          required: requiredIf(this.action === 'add'),
          minLength: minLength(1),
        },
      },
    };
  },
  data() {
    return {
      loading: {
        table: false,
        division_list: false,
      },
      usersList: [],
      showModal: false,
      showModalEdit: false,
      search: {
        search: this.$route.query.search || '',
        page: this.$route.query.page || 1,
        per_page: this.$route.query.per_page || 10,
      },
      page: 1,
      totalRow: 0,
      editedId: '',
      action: 'add',
      userForm: {
        name: '',
        phone_number: '',
        email: '',
        password: '',
        is_pic: false,
        groups_id: [],
        is_admin: false,
        company_id: this.$store.state.company.activeCompany.id,
      },
      division_list: [],
      timeout_search_division: null,
      company_id: this.$store.state.company.activeCompany.id,
    };
  },
  computed: {
    currentPage: {
      get() {
        return this.search.page;
      },
      set(val) {
        this.search.page = val;
        this.getUserList();
      },
    },
    activeWorkspace() {
      return this.$store.state.workspace.activeWorkspace;
    },
    userProfile() {
      return this.$store.state.auth.userProfile || {};
    },
    checkPermission() {
      const users = this.$store.state.backOffice.userProfile;
      const path = this.$router.currentRoute.fullPath;
      const permission = users.permissions.find((v) => path.includes(v.path));
      return permission.configuration;
    },
  },
  mounted() {
    this.getUserList();
  },
  methods: {
    doSearch() {
      this.page = 1;
      this.getUserList();
    },
    async getUserList() {
      const options = {
        page: this.search.page.toString(),
        per_page: this.search.per_page.toString(),
        search: this.search.search,
        // search_keyword: this.search.search_keyword,
      };
      if (!isEqual(options, this.$route.query)) {
        this.$router.replace({ query: options });
      }
      const loader = this.$loading.show();
      this.loading.table = true;
      await usersAPI.getList(this.search.page, this.search.search, this.company_id)
        .then((res) => {
          this.totalRow = res.data.total;
          this.usersList = res.data.rows.map((user) => {
            const data = user;
            data.image = avatar(user.name, user.email);
            ['created_at', 'last_login_at'].forEach((v) => {
              data[`${v}_str`] = user[v] ? moment(user[v]).format('DD MMM YYYY, HH:mm') : '-';
            });
            return data;
          });
        });
      this.loading.table = false;
      loader.hide();
    },
    clearUserForm() {
      this.userForm = {
        name: '',
        phone_number: '',
        email: '',
        password: '',
        is_pic: false,
        company_id: this.company_id,
      };
    },
    closeModal() {
      this.editedId = '';
      this.showModal = false;
      this.showModalEdit = false;
      this.v$.userForm.$reset();
      this.clearUserForm();
    },
    addUser() {
      this.action = 'add';
      this.clearUserForm();
      this.showModal = true;
      this.v$.userForm.$touch();
      this.getListDivisions();
    },
    async createUser() {
      this.v$.userForm.$touch();
      if (this.v$.userForm.$error) return;
      const loader = this.$loading.show();
      await usersAPI.createUser(this.userForm)
        .then(async (response) => {
          console.log(this.userForm);
          await popupErrorMessages(response);
          const response2 = await this.addUserToDivision(response.data.users.id);
          await popupErrorMessages(response2);
          this.$message({
            message: this.$t('users.success.add'),
            type: 'success',
          });
          this.page = 1;
          this.search = {
            search: this.$route.query.search || '',
            page: this.$route.query.page || 1,
            per_page: this.$route.query.per_page || 10,
          };
          this.getUserList();
          this.closeModal();
        }).catch((err) => { console.log(err); });
      loader.hide();
    },
    editUser(user) {
      this.action = 'edit';
      this.v$.userForm.$touch();
      this.editedId = user.id;

      this.userForm.name = user.name;
      this.userForm.phone_number = user.phone_number;
      this.userForm.email = user.email;
      this.userForm.password = '';
      this.userForm.is_pic = user.is_pic;
      this.userForm.company_id = this.company_id;

      this.showModalEdit = true;
    },
    async saveEdit() {
      this.v$.userForm.$touch();
      if (this.v$.userForm.$error) return;
      const loader = this.$loading.show();
      if (this.userForm.password) {
        this.userForm.password = RSA.encrypt(this.userForm.password, 'base64');
      }
      await usersAPI.update(this.editedId, this.userForm)
        .then(async (response) => {
          await popupErrorMessages(response);
          this.$message({
            message: this.$t('users.success.edit'),
            type: 'success',
          });
          this.page = 1;
          this.search = {
            search: this.$route.query.search || '',
            page: this.$route.query.page || 1,
            per_page: this.$route.query.per_page || 10,
          };
          this.getUserList();
          this.closeModal();
        });
      loader.hide();
    },
    async deleteUser(id) {
      this.$confirm(this.$t('users.confirm.delete'), this.$t('general.confirmation'), {
        confirmButtonText: this.$t('general.yes'),
        cancelButtonText: this.$t('general.no'),
        type: 'warning',
        center: true,
        /* eslint-disable no-param-reassign */
        beforeClose: async (action, instance, cb) => {
          if (action === 'confirm') {
            instance.confirmButtonLoading = true;
            await usersAPI.delete(id)
              .then(async (response) => {
                await popupErrorMessages(response);
                this.$message({
                  message: this.$t('users.success.delete'),
                  type: 'success',
                });
                this.page = 1;
                this.search = {
                  search: this.$route.query.search || '',
                  page: this.$route.query.page || 1,
                  per_page: this.$route.query.per_page || 10,
                };
                this.getUserList();
              });
          }
          instance.confirmButtonLoading = false;
          cb();
        },
      }).catch(() => {});
    },
    async addUserToDivision(user_id) {
      const response = await divisions.memberAdd({
        groups_id: this.userForm.groups_id,
        users_id: [user_id],
        is_admin: this.userForm.is_admin,
      });
      return response;
    },
    async getListDivisions(search_keyword = '') {
      this.loading.division_list = true;
      const options = {
        page: 1,
        per_page: 10,
        search_by: 'name',
        search_keyword,
        company_id: this.company_id,
      };
      const response = await divisions.getList(options);
      this.loading.division_list = false;
      await popupErrorMessages(response);
      const { data } = response;
      if (this.userForm.groups_id.length) {
        this.division_list = this.division_list.filter((v) => this.userForm.groups_id.includes(v));
      } else {
        this.division_list = [];
      }
      this.division_list = data.rows.map((v) => {
        v.created_at_str = moment(v.created_at).format('DD MMM YYYY, HH:mm');
        return v;
      });
    },
    handlerSearchDivision(value) {
      clearTimeout(this.timeout_search_division);
      this.timeout_search_division = setTimeout(() => {
        this.getListDivisions(value);
      }, 500);
    },
  },
};
</script>
